<template>
  <div
    class="slot-style"
    :class="{
      'slot-style-selected': isSelected,
      'slot-style-hover': canBeClicked,
    }"
  >
    <div>
      <div v-if="withDate">
        <strong>{{ moment(slotObject.date).format("DD.MM.YYYY") }}</strong>
      </div>
      <strong>{{ `${slotObject.time} ${$t("misc.oClock")}`}}</strong>
      <br />
      <span
          class="slot-text"
        style="white-space: pre-line"
        v-if="slotObject.metadata && slotObject.metadata.extraDescription"
      >
        {{ slotObject.metadata.extraDescription }}
      </span>
    </div>
    <div class="slot-delete" v-if="withDeleteIcon">
      <b-button
        pill
        size="sm"
        variant="danger"
        style="font-size: 0.9em"
        @click="onDelete"
      >
        <b-icon-trash />
      </b-button>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
export default {
  name: "calendarSlotTile",
  props: {
    slotObject: Object,
    canBeClicked: { type: Boolean, default: false },
    isSelected: { type: Boolean, default: false },
    withDeleteIcon: { type: Boolean, default: false },
    onDelete: { type: Function },
    withDate: { type: Boolean, default: false }
  },
  data() {
    return {
      moment
    };
  }
};
</script>

<style scoped></style>
